import React from 'react';
import CreateSquad from 'containers/Squads/modals/CreateSquad';
import AddMemberForm from 'containers/Squads/modals/addMemberForm';
import RemoveSquadMember from 'containers/Squads/modals/removeSquadMember';
import EditSquadPower from 'containers/ShiftShedule/modals/EditSquadPower';
import AssignShiftModal from 'containers/ShiftShedule/modals/AssignShiftModal';
import {
  PriorityRemoveModal,
  PriorityEditModal,
  AssignPriorityModal,
} from 'containers/Priorities/Modals';

import {
  AddNewSla,
  AddNewPriority,
  DeleteSla,
  EditExistingPriority,
  ArrangeRanks,
} from 'containers/Priorities/SettingsView/Modals';

import {
  SquadMatchesDetails,
  AssignSquadShiftMatchesModal,
} from 'containers/MatchAssignment/modals';

import {
  AssignTasks,
  BlockMatch,
  UnBlockMatch,
  EditTask,
  ReassignMatch,
  SwapMatch,
  UnassignMatch,
  ReportIssue,
  ResolveIssue,
  ViewDetails,
  ViewTasks,
} from 'containers/Dashboard/modals';
import SignOut from 'components/SignOut';
import {
  ChangePassword,
  CreateEditForm,
  DeactivateConfirm,
  DeleteConfirm,
  ResetMFA,
  ResetPassword,
} from 'containers/UserManagement/Modals';
import UnassignShiftModal from 'containers/ShiftShedule/modals/UnassignShiftModal';
import CreateUpdatePc from 'containers/Video/Pcs/modals/CreateUpdatePc';
import DeletePc from 'containers/Video/Pcs/modals/DeletePc';
import CreateUpdateProvider from 'containers/Video/Providers/modals/CreateUpdateProvider';
import DeleteProvider from 'containers/Video/Providers/modals/DeleteProvider';
import CreateLeagueModal from 'containers/Video/Leagues/modals/createLeague';
import DeleteLeagueModal from 'containers/Video/Leagues/modals/deleteLeague';
import ViewPCMatches from 'containers/PcAssignment/modals/pcMatchesModal';
import ConfirmInitImpersonation from 'components/ConfirmInitImpersonation';
import EditSquadName from 'containers/Squads/modals/editSquadName';

const MODAL_COMPONENT = {
  CREATE_USER: <CreateEditForm />,
  EDIT_USER: <CreateEditForm />,
  DELETE_USER: <DeleteConfirm />,
  DEACTIVATE_USER: <DeactivateConfirm />,
  RESET_MFA: <ResetMFA />,
  RESET_PASSWORD: <ResetPassword />,
  ADD_SQUAD: <CreateSquad />,
  ADD_SQUAD_MEMBER: <AddMemberForm />,
  REMOVE_SQUAD_USER: <RemoveSquadMember />,
  ASSIGN_PRIORITY: <AssignPriorityModal />,
  EDIT_PRIORITY: <PriorityEditModal />,
  REMOVE_PRIORITY: <PriorityRemoveModal />,
  ADD_NEW_SLA: <AddNewSla />,
  DELETE_SLA: <DeleteSla />,
  ADD_NEW_PRIORITY: <AddNewPriority />,
  EDIT_EXISTING_PRIORITY: <EditExistingPriority />,
  ARRANGE_RANKS: <ArrangeRanks />,
  EDIT_SQUAD_POWER: <EditSquadPower />,
  ASSIGN_SHIFT_MODAL: <AssignShiftModal />,
  UNASSIGN_SHIFT_MODAL: <UnassignShiftModal />,
  SQUAD_MATCHES_DETAILS: <SquadMatchesDetails />,
  ASSIGN_SQUAD_SHIFTS_MATCHES: <AssignSquadShiftMatchesModal />,
  EDIT_TASK: <EditTask />,
  BLOCK_MATCH: <BlockMatch />,
  UN_BLOCK_MATCH: <UnBlockMatch />,
  REASSIGN_MATCH: <ReassignMatch />,
  SWAP_MATCH: <SwapMatch />,
  UNASSIGN_MATCH: <UnassignMatch />,
  ASSIGN_TASK: <AssignTasks />,
  REPORT_ISSUE: <ReportIssue />,
  RESOLVE_ISSUE: <ResolveIssue />,
  VIEW_DETAILS: <ViewDetails />,
  SIGN_OUT: <SignOut />,
  CHANGE_PASSWORD: <ChangePassword />,
  VIEW_TASKS: <ViewTasks />,
  CREATE_UPDATE_PC: <CreateUpdatePc />,
  DELETE_PC: <DeletePc />,
  CREATE_UPDATE_PROVIDER: <CreateUpdateProvider />,
  DELETE_PROVIDER: <DeleteProvider />,
  CREATE_LEAGUE: <CreateLeagueModal />,
  EDIT_LEAGUE: <CreateLeagueModal />,
  DELETE_LEAGUE: <DeleteLeagueModal />,
  VIEW_PC_MATCHES: <ViewPCMatches />,
  ENTER_IMPERSONATION: <ConfirmInitImpersonation />,
  EDIT_SQUAD_NAME: <EditSquadName />,
};
export default MODAL_COMPONENT;
