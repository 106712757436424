export default {
  'components.tab': '',
  'containers.users.add': 'Add New User',
  'containers.users.edit': 'Edit User',
  'containers.users.form.empDetails': 'Employee Details',
  'containers.users.form.createTitle': 'Create Account',
  'containers.users.form.hr': 'HR Code',
  'containers.users.form.firstName': 'First Name',
  'containers.users.form.middleName': 'Middle Name',
  'containers.users.form.lastName': 'Last Name',
  'containers.users.form.businessLine': 'Business Line',
  'containers.users.form.group': 'Team',
  'containers.users.form.lineFunction': 'Function',
  'containers.users.form.team': 'Team',
  'containers.users.form.multiSport': 'Multi-Sport',
  'containers.users.form.line': 'Line',
  'containers.users.form.jobType': 'Employment Type',
  'containers.users.form.power': 'Power',
  'containers.users.form.email': 'Email',
  'containers.users.form.hiringDate': 'Hiring Date',
  'containers.users.form.save': 'Save',
  'containers.users.form.cancel': 'Cancel',
  'containers.users.form.mobile': 'Mobile Number',
  'containers.users.form.successCreate': 'The user was created successfully.',
  'containers.users.form.successEdit': 'The user was updated successfully.',
  'containers.users.error':
    'Oops, looks like something went wrong. Please try again.',
  'containers.users.accountStatus': 'Account Status',
  'containers.users.jobTitle': 'Job Title',
  'containers.users.tabs.users': 'Users',
  'containers.users.tabs.categories': 'Squad Categories',
  'containers.users.tabs.squads': 'Squads',
  'containers.users.modals.resetMFA.title': 'Reset MFA',
  'containers.users.modals.resetMFA.main':
    'You are resetting the MFA for the following account:',
  'containers.users.modals.resetMFA.success':
    'MFA has been reset successfully for this user.',
  'containers.users.modals.resetMFA.error':
    'Oops, looks like something went wrong. Please try again.',
  'containers.squads.addNewCategory': 'Add new Category',
  'containers.squads.addNewSquad': 'Add new Squad',
  'containers.squads.viewSquads': 'View all Squads',
  'containers.squads.squadCount': 'Contains {squadsCount} squad',
  'containers.squads.createCategory.error':
    'Oops, looks like something went wrong. Please try again.',
  'containers.squads.createCategory.success':
    'The new Category was created successfully.',
  'containers.squads.lineFunctionName': 'Function Name',
  'containers.squads.createCategory.enableQa': 'This is a QA Category',
  'containers.squads.createCategory.createSquad':
    'You need to create new Squads to add to the new Category.',
  'containers.squads.squadName': 'Squad Name',
  'containers.squads.dropDown.members': 'Members',
  'containers.squads.createCategory.addCollectionSquads':
    'Assign Collection Squads for this QA Category.',
  'containers.squads.createCategory.squads': 'squads',
  'containers.squads.createCategory.btn.addCategory': 'Add Category',
  'containers.squads.createSquad.error':
    'Oops, looks like something went wrong. Please try again.',
  'containers.squads.createSquad.success':
    'New Squad has been added successfully.',
  'containers.squads.updateName.success':
    'Squad Name has been updated successfully.',
  'containers.squads.createSquad.squadDetails': 'Squad Details',
  'containers.squads.category': 'Category',
  'containers.squads.createSquad.collectionSquad': 'Collection Squads',
  'containers.squads.createSquad.addSquad': 'Add Squad',
  'containers.squads.moreCollectionSquad': 'Squad {collectionSquadName}',
  'containers.squads.members': 'members',
  'containers.squads.member': 'member',
  'containers.squads.collectionSquad': 'collectionSquads',
  'containers.squads.collectionSquad.hasMore': '+{collectionSquads} More',
  'containers.squads.totalPower': 'Total Power',
  'containers.squads.editSquad': 'Edit Squad',
  'containers.squads.addNewMember': 'Add new Member',
  'containers.squads.editSquadName': 'Edit Squad Name',
  'containers.squads.editCategoryName': 'Edit Category Name',
  'containers.squads.editCategoryName.error':
    'Oops, looks like something went wrong. Please try again.',
  'containers.squads.editCategoryName.success':
    'Category name has been updated successfully.',
  'containers.squads.removeSquadMember.title': 'Remove Member',
  'containers.squads.removeSquadMember.description.part1':
    'Are you sure you want to remove the following user from the Squad',
  'containers.squads.removeSquadMember.description.part2':
    'You can add them again later.',
  'containers.squads.removeSquadMember.success':
    'The team memeber has been removed successfully.',
  'containers.squads.addSquadMember.success': 'Member Modified',
  'containers.squads.editQaSquadModal.success': 'QA Squad Modified',
  'containers.squads.editQaSquadModal.title': 'Edit QA Squad',
  'containers.priorities.title.generalSettings': 'General Settings',
  'containers.priorities.teamType': 'Team Type',
  'containers.priorities.assignPriority': 'Assign Priority',
  'containers.priorities.removeTeam': 'Remove Team',
  'containers.priorities.removeTeam.success':
    'This {screenType} has been removed successfully.',
  'containers.priorities.removeTeam.title': 'Remove {screenType}',
  'containers.priorities.removeTeam.description':
    'Are you sure you want to remove the following {screenType} from the priority ',
  'containers.priorities.assignPriority.teamDetails': 'Teams Details',
  'containers.priorities.teams': 'Teams',
  'containers.priorities.CompetitionSeason': 'Competition Season',
  'containers.priorities.Competition': 'Competitions',
  'containers.priorities.assignPriority.warning':
    'Please remove {label} to proceed.',
  'containers.priorities.priorityDetails': 'Priority Details',
  'containers.priorities.priority': 'Priority',
  'containers.priorities.sla': 'SLA',
  'containers.priorities.editAssignedPriority': 'Edit Assigned Priority',
  'containers.priorities.header.teams.team': 'Team',
  'containers.priorities.header.teams.type': 'Type',
  'containers.priorities.header.Country': 'Country',
  'containers.priorities.header.competition.league': 'League',
  'containers.priorities.header.competition.customerTeamsAmount':
    'Customer Teams Amount',
  'containers.priorities': 'Priorities',
  'containers.priorities.arrangeRanks': 'Arrange Ranks',
  'containers.priorities.settings.title.team': 'Team Priorities',
  'containers.priorities.settings.title.competition': 'Competition Priorities',
  'containers.priorities.settings.title.addNewPriority': 'Add new Priority',
  'containers.priorities.settings.title.addNewSla': 'Add new SLA',
  'containers.priorities.settings.newSla': 'New SLA',
  'containers.priorities.rank': 'Rank',
  'containers.priorities.slas': 'SLAs',
  'containers.priorities.settings.addNewSla.success':
    'A new SLA has been added successfully.',
  'containers.priorities.settings.arrangeRanks.error':
    'Oops, looks like something went wrong. Please try again.',
  'containers.priorities.settings.arrangeRanks.description':
    'Drag the priorities to rearrange the assigned Rank.',
  'containers.priorities.addCustomSla':
    'This priority will be saved as {priorityName}:{newSla} Hrs.',
  'containers.priorities.settings.addNewPriority.warning':
    'The name you entered is already taken, please enter another name.',
  'containers.priorities.settings.btn.updateRanks': 'Update Ranks',
  'containers.priorities.settings.editPriority.title': 'Edit Priority',
  'containers.matchAssignment.matches': 'Matches',
  'containers.matchAssignment.title.autoAssigning': 'Auto Assigning ',
  'containers.matchAssignment.assignMatches.description.part1':
    'Click here or drag file to upload.',
  'containers.matchAssignment.assignMatches.description.part2':
    'Accepts .csv files only.',
  'containers.matchAssignment.assignMatches.description.part3':
    'Please ensure the file format is: matchId, squadShiftId.',
  'containers.matchAssignment.assignMatches.btn.autoAssigning':
    'Start Auto Assigning',
  'containers.priorities.settings.title.deleteSla': 'Delete SLA',
  'containers.priorities.settings.description.deleteSla':
    'Are you sure you want to delete this SLA?',
  'containers.priorities.settings.deleteSla.error':
    'Oops, looks like something went wrong. Please try again.',
  'containers.priorities.settings.deleteSla.success':
    'The SLA has been deleted successfully.',
  'containers.priorities.settings.addNewPriority.error':
    'Oops, looks like something went wrong. Please try again.',
  'containers.shiftSchedule.header.btn.editSquadPower': 'Edit Squad Power',
  'containers.shiftSchedule.header.btn.assignShift': 'Assign Shift',
  'containers.shiftSchedule.header.btn.editShift': 'Edit Shift',
  'containers.shiftSchedule.header.squads.title': 'Squads',
  'containers.shiftSchedule.header.shiftSchedule.squads.title':
    'Shifts Schedule',
  'containers.shiftSchedule.updateShiftMember.error': 'Failed to update',
  'containers.shiftSchedule.assignShift.membersAdded': 'Members Added',
  'containers.shiftSchedule.assignShift.shiftsCreated': 'Shifts Created',
  'containers.shiftSchedule.assignShift.shiftsUpdated': 'Shifts Updated',
  'containers.shiftSchedule.editSquadPower.warning':
    "Changes are applied for the {label} . If you wish to change the user's details completely, you must change it from Users Management.",
  'containers.dashboards.assignTask.collectorSelectTitle': 'Assign Collectors',
  'containers.dashboards.assignTask.countinfo':
    'You can only assign {maxCount} tasks at a time',
  'containers.dashboards.assignTask.countWarning':
    'You can only assign {maxCount} tasks at a time. Please click assign and start again.',
  'containers.dashboards.editTask.confirm': 'Are you sure?',
  'containers.dashboards.hello': 'Hello',
  'containers.dashboards.welcome': 'welcome back.',
  'containers.dashboards.headLine':
    'You can track the Shift Progress from here.',
  'containers.dashboards.noShiftMatches':
    'There are no matches available in this shift.',
  'containers.dashboards.noSquad':
    "This Team Leader doesn't have an assigned squad.",
  'containers.dashboards.shiftMatches.count': 'Showing {totalCount} Match',
  'containers.dashboards.matchRow.AssignedSquad': 'Assigned Squad: ',
  'containers.dashboards.matchRow.videoStatus': 'Video',
  'containers.dashboards.matchRow.noVideo': 'No Video',
  'containers.dashboards.unassign.title': 'Unassign Match',
  'containers.dashboards.unassign.description':
    'Are you sure you want to unassign this match?',
  'containers.dashboards.squadAssigned':
    'Assigned to Squad {assignedSquadName}',
  'containers.dashboards.unassign': 'Unassign',
  'containers.dashboards.unassign.success':
    'The match has been unassigned successfully.',
  'containers.dashboards.reassign': 'Reassign Match',
  'containers.dashboards.reassign.title': 'Reassign Match',
  'containers.dashboards.reassign.success':
    'The match has been reassigned successfully.',
  'containers.dashboards.reassign.sameShiftError':
    'You have selected the same day and shift. Please choose another date.',
  'containers.dashboard.blocking.explain': 'Please explain the issue.',
  'containers.dashboards.report.success':
    'Your issue has been reported successfully.',
  'containers.dashboards.update.success':
    'Your issue has been edited successfully.',
  'containers.dashboards.update.issue.title': 'Edit Report',
  'containers.dashboards.report.issue.title': 'Report Issue',
  'containers.dashboards.blocking.title': 'Block Match',
  'containers.dashboards.unblock.title': 'Unblock Match',
  'containers.dashboards.unblock.subTitle': 'Blocked Match',
  'containers.dashboards.unblock.success':
    'The match has been unblocked successfully.',
  'containers.dashboards.emptyMatchDetails':
    'There is no information to display for this match.',
  'containers.dashboard.editMyReport': 'Edit my Report',
  'containers.dashboards.resolve.success':
    'The reported issues have been resolved successfully.',
  'containers.dashboard.reportedIssues': 'Reported Issues',
  'containers.dashboard.coordinatorAssignedSuccess':
    'The coordinator has been assigned to the match successfully.',
  'containers.dashboard.videoStatusUpdatedSuccess':
    'The match status has been updated to {updatedVideoStatus} successfully.',
  'containers.dashboard.coordinatorUnAssignedSuccess':
    'The coordinator has been unassigned to the match successfully.',
  'containers.dashboards.resolve.title': 'Resolve Issues',
  'containers.dashboards.assignTasks.title': 'Assign Tasks',
  'containers.dashboards.videoCard.acq': 'Acq. Type: ',
  'containers.dashboards.videoCard.plannedAcqTime': 'Planned Acq.Time: ',
  'containers.resolve.qualityIssue.warning':
    'One of the issues you’ve selected is a Quality Issue. Make sure you want to resolve it.',
  'containers.dashboards.swap.title': 'Swap Match',
  'containers.dashboards.swap.squad': 'Assigned to Squad {assignedSquadName}',

  'containers.users.card.contact.details': 'Contact Details',
  'containers.all.comment': 'Comment',
  'containers.all.resolveAll': 'Resolve All',
  'containers.all.resolve': 'Resolve',
  'containers.all.from': 'From',
  'containers.all.to': 'To',
  'containers.all.timeStamp': 'Timestamp',
  'containers.all.playerName': 'Player Name',
  'containers.all.number': 'Number',
  'containers.all.reason': 'Reason',
  'containers.all.timeStamp.warning':
    'To input the Timestamp correctly please make sure you are using the following format: 00:00.000',
  'containers.all.totalPower': 'Total Power',
  'containers.all.power': 'Power',
  'containers.all.shiftType': 'Shift Type',
  'containers.all.pagination.showingTotalMsg':
    'Showing {totalCount} {screenTitle}',
  'containers.all.pagination.showOnPageCount': 'Show on page: {pageSize}',
  'containers.all.error':
    'Oops, looks like something went wrong. Please try again.',
  'containers.all.success': 'The changes have been saved successfully.',
  'containers.all.btn.cancel': 'Cancel',
  'containers.all.btn.saveChanges': 'Save Changes',
  'containers.all.btn.save': 'Save',
  'containers.all.btn.edit': 'Edit',
  'containers.all.btn.reset': 'Reset',
  'containers.all.btn.tryAgain': 'Try Again',
  'containers.all.dropdown.country': 'Country',
  'containers.all.dropdown.noResult': 'No Results',
  'containers.all.remove': 'Remove',
  'containers.all.required': 'Required',
  'containers.all.delete': 'Delete',
  'containers.all.name': 'Name',
  'containers.all.errorSaveChanges':
    'Oops, looks like something went wrong. Please try again.',
  'containers.all.close': 'Close',
  'containers.all.upload': 'Upload',
  'containers.all.errorCannotEdit':
    'Oops, looks like something went wrong. Please try again.',
  'containers.all.employeeName': 'Employee Name',
  'containers.all.addNewReason': 'Add new Reason',
  'containers.all.unBlock': 'Unblock',
  'containers.all.details': 'Details',
  'containers.all.block.success': 'The match has been blocked successfully.',
  'containers.all.Comments': 'Comments',
  'containers.all.reportedBy': 'Reported by',
  'containers.all.resolvedBy': 'Resolved by:',
  'containers.all.edited': 'edited',
  'containers.dashboards.matchRow.half': 'Half {half}:',
  'containers.all.Categories': 'Categories',
  'containers.all.squad': 'Squad',
  'containers.all.mobile': 'Mobile',
  'containers.all.History': 'History',
  'containers.users.history.promotions': 'Promotions',
  'containers.all.date': 'Date',
  'containers.users.history.teamChanges': 'Team Changes',
  'containers.users.history.accountStatus': 'Account Status',
  'containers.users.history.deactivated': 'Deactivated',
  'containers.users.history.activated': 'Activated',

  'containers.users.header.employeeName': 'Employment Name',
  'containers.users.header.sport': 'Sport',
  'containers.users.header.businessLine': 'Business Line',
  'containers.users.header.team': 'Team',
  'containers.users.header.jobTitle': 'Job Title',
  'containers.users.header.jobType': 'Job Type',

  'containers.users.menu.edit': 'Edit',
  'containers.users.menu.delete': 'Delete',
  'containers.users.menu.resetPassword': 'Reset Password',
  'containers.users.menu.resetMfa': 'Reset MFA',
  'containers.users.menu.reactivate': 'Reactivate',
  'containers.users.menu.deactivate': 'Deactivate',
  'containers.users.menu.remove': 'Remove',
  'containers.users.modals.resetPassword.main':
    'You are resetting the password for the following account:',
  'containers.users.modals.resetPassword.success':
    'An email has been sent successfully to this user to reset their password.',
  'containers.users.modals.delete.error': "Couldn't delete user",
  'containers.users.modals.delete.success': 'User was deleted successfully',
  'containers.users.modals.delete.title': 'Delete User',
  'containers.users.modals.delete.content':
    'Deleting an account is a permanent action. Are you sure you want to delete the following user’s account?',

  'containers.users.modals.deactivate.success':
    'User was {modalType} successfully',
  'containers.users.modals.reactivate.content':
    'You are about to reactivate the following user’s account:',
  'containers.users.modals.deactivate.content':
    "Choose a reason below to deactivate the following User's account:",
  'containers.users.modals.changePassword.success': 'User Password Updated',
  'containers.users.modals.changePassword.title': 'Change Password',
  'containers.users.modals.changePassword.content':
    'Please enter your new Password.',
  'containers.users.modals.changePassword.password': 'Password',
  'containers.users.modals.changePassword.passwordConfirmation':
    'Password Confirmation',
  'containers.all.invalidMatchId': 'Please Search by valid match ID',
  'containers.all.emptyMatches': 'Your search returned no matches.',
  'containers.all.unassigned': 'Unassigned',
  'containers.all.pagination.end.warning': 'Looks like you’ve reached the end.',
  'containers.all.cannot.edit': "can't edit,Please try again",
  'layout.rotatePhone':
    'Please rotate your phone for a better viewing experience.',
  'containers.dashboards.assign': 'Assign',
  'containers.dashboards.assign.title': 'Assign Members',
  'containers.dashboards.editTasks.title': 'Edit',
  'containers.all.limit.access':
    "This Route doesn't exist or you Don't have access",
  'containers.all.totalPower.week': "Week's Total power:",
  'containers.filters.line': 'Line',
  'containers.shiftSchedule.video.header.subTitle': 'Available Users',
  'containers.shiftSchedule.video.weekTotals': 'Week’s Total Work Days:',
  'containers.shiftScheule.unassign.title': 'Unassign Shift',
  'containers.shiftSchedule.unassign.description':
    'Are you sure you want to unassign this Shift ?',

  'containers.shiftSchedule.unassign.title': 'Unassign Shift',
  'containers.shiftSchedule.unassignShift.success':
    'The shifts have been unassigned successfully.',
  'containers.pcs.createPc.title': 'Create PC',
  'containers.pc.form.pcSelect': 'Select a PC',
  'containers.pc.form.pcNumber': 'PC Number',
  'containers.pc.form.pcDetails': 'PC Details',
  'containers.pc.form.location': 'Location',
  'containers.pc.form.computerTask': 'PC Task',
  'containers.pc.form.pcFeatures': 'PC Features',
  'containers.pc.form.satellites': 'Satellites',
  'containers.pc.form.vpn': 'VPN',
  'containers.pc.form.liveServer': 'Live Server',
  'containers.pc.form.successCreate': 'The PC was created successfully.',
  'containers.pcs.updatePc.title': 'Update PC',
  'containers.pc.modals.delete.title': 'Delete PC',
  'containers.pc.modals.delete.success': 'PC was deleted successfully',
  'containers.pc.modals.delete.content':
    'Deleting a PC is a permanent action. Are you sure you want to delete the following PC?',
  'containers.providers.createProvider.title': 'Create Provider',
  'containers.providers.form.providersDetails': 'Providers Details',
  'containers.providers.form.streamingname': 'Streaming Name',
  'containers.providers.form.type': 'Provider Type',
  'containers.providers.form.providerFeatures': 'Provider Features',
  'containers.providers.form.noOfAccounts': 'Number of accounts',
  'containers.providers.form.accConcurrency': 'Account Concurrency',
  'containers.providers.form.iptvName': 'IPTV Name',
  'containers.providers.form.channelDetails.name': 'Channel Name',
  'containers.providers.form.satellite': 'Satellite Name',
  'containers.providers.form.delete.provider.title': 'Delete Provider',
  'containers.providers.modals.delete.content':
    'Deleting a Provider is a permanent action. Are you sure you want to delete the following provider?',
  'containers.providers.UpdateProvider.title': 'Update Provider',
  'containers.provider.modals.delete.success':
    'Provider was deleted successfully',
  'containers.provider.form.successCreate':
    'The Provider was created successfully.',
  'containers.provider.form.successUpdate':
    'The Provider was updated successfully.',
  'containers.leagues.add': 'Add New League',
  'containers.leagues.edit': 'Edit League',
  'containers.leagues.provider': 'Provider',
  'containers.leagues.defaultProvider': 'Default Provider',
  'containers.leagues.live': 'Live',
  'containers.leagues.competitor': 'Competitor Available (3rd party)',
  'containers.leagues.create.success': 'League created successfully',
  'containers.leagues.edit.success': 'League updated successfully',
  'containers.league.Competition': 'Competition',
  'containers.leagues.uniq': 'Providers should be unique',
  'containers.leagues.delete.success': 'League deleted successfully',
  'containers.leagues.delete': 'Delete League',
  'containers.pc.modals.update.success': 'PC was updated successfully',
  'containers.video.vod': 'VOD',
  'containers.video.Card.attributes.matchId': 'Match Id: ',
  'containers.video.Card.attributes.country': 'Country',
  'containers.video.Card.attributes.ko': 'KO: ',
  'containers.video.Card.attributes.acq': 'Acq.Type: ',
  'containers.video.Card.attributes.videoETA': 'Video ETA: ',
  'containers.video.Card.attributes.room': 'PC room:',
  'containers.video.Card.attributes.assignedPC': 'Assigned PC:',
  'containers.acquisition.main.saved.success':
    'Acquisitions types saved successfully.',
  'containers.acquisition.main.update.success':
    'Acquisition type updated successfully.',
  'containers.acquisition.main.update.error':
    'Acquisition type cannot be updated. Please try again',
  'containers.acquisitionTypes.form.type': 'Acquisition Types',
  'containers.acquisitionTypes.form.interval': 'Acquisition Interval',
  'containers.video.matchAssignment.error': `Can't assign match(es), Kick-Off time is after the shift end time`,
  'containers.all.appleTv': 'Apple TV',
  'containers.all.bein': 'Bein',
  'containers.pcAssignment.iptvDevice': 'IPTV Device',
  'containers.pc.form.satellite': 'Satellite',
  'containers.pc.noAssignedMatches': 'No assigned matches available.',
  'containers.pc.noUnAssignedMatches': 'No unassigned matches available.',
  'containers.pc.viewMatches': 'View all PC matches',
  'containers.all.errors.saving.changes': 'Changes can not be saved try again.',
  'containers.providers.existing.provider':
    'Oops! This provider already exists. Please double-check and try adding a new provider',
  'containers.dashboards.matchRow.videoMatchesInfo': 'Match Information:',
  'containers.dashboards.matchRow.assignedCoordinator': 'Assigned Coordinator',
  'containers.dashboard.issueTypes': 'Issue Types',
  'containers.dashboard.assignedCoordinators': 'Assigned Coordinator',
  'containers.dashboards.chooseCoordinator': 'Choose Coordinator',
  'containers.dashboard.reassign.no.squads':
    'There are no squads available in this shift.',
  'containers.dashboard.filters.room': 'Rooms',
  'containers.acquisition.default.filter': 'Show Only Default Provider results',
  'containers.impersonation.modal.title': 'View as {type}',
  'containers.impersonation.modal.switch': 'You are about to switch to {type}',
  'containers.impersonation.modal.viewOnlyMode':
    'In this view-only mode, you can operate under a different identity without the ability to make any changes. All activities will be logged for transparency.',
  'containers.all.continue': 'Continue',
  'containers.all.impersonation.banner.content':
    'You are currently in Ops {type} view mode.',
};
