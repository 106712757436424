import moment from 'moment';
import { ISSUES_TYPES, VIDEO_ISSUES } from 'utils/enums';
import * as Yup from 'yup';

const nameRegex = /^(?=.{1,40}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/;
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const TIME_REGEX = /^(([0-5]\d)|60)(:[0-5]\d)(\.\d{3})$/;
const PASSWORD_REGEX = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9_])/;
const minNameChar = 2;
const maxNameChar = 15;

export const USER_VALIDATION = Yup.object().shape({
  hrCode: Yup.string().required('Required'),
  firstName: Yup.string()
    .trim()
    .required('Required')
    .matches(nameRegex, 'Please enter a valid first name')
    .min(minNameChar, "First name can't be less than 2 characters")
    .max(maxNameChar, "First name can't be more than 15 characters"),
  lastName: Yup.string()
    .trim()
    .required('Required')
    .matches(nameRegex, 'Please enter a valid  last name')
    .min(minNameChar, "Last name can't be less than 2 characters")
    .max(maxNameChar, "Last name can't be more than 15 characters"),

  middleName: Yup.string()
    .trim()
    .nullable()
    .notRequired()
    .matches(nameRegex, 'Please enter a valid  middle name')
    .min(minNameChar, "Middle name can't be less than 2 characters")
    .max(maxNameChar, "Middle name can't be more than 15 characters"),

  email: Yup.string()
    .email()
    .required('Required')
    .matches(emailRegex, 'Please enter a valid email'),
  mobileNumber: Yup.number()
    .typeError('You must specify a number')
    .nullable(true),
  hiringDate: Yup.string().required('Required'),
  jobTitleId: Yup.string().required('Required'),
  jobType: Yup.string().required('Required'),
  sport: Yup.string().required('Required'),
  lineId: Yup.string().required('Required'),
});

export const CREATE_CATEGORY_VALIDATION = Yup.object().shape({
  categoryName: Yup.string().required('Required'),
  squadName: Yup.string().required('Required'),
  isQa: Yup.boolean().required('Required'),
  members: Yup.array().when('isQa', {
    is: false,
    then: Yup.array().min(1),
    otherwise: Yup.array().min(0),
  }),
  collectionSquads: Yup.array().when('isQa', {
    is: true,
    then: Yup.array().min(1),
    otherwise: Yup.array().min(0),
  }),
});

export const CREATE_SQUAD = Yup.object().shape({
  lineFunctionId: Yup.string().required('Required'),
  squadName: Yup.string().required('Required'),
  members: Yup.array().min(1).required('Required'),
});

export const ADD_NEW_PRIORITY = Yup.object().shape({
  name: Yup.string().required('Required'),
  rank: Yup.number().required().typeError('You must specify a number'),
});
export const UPDATE_PRIORITY = Yup.object().shape({
  name: Yup.string().required('Required'),
});

export const ASSIGN_TASK = Yup.object().shape({
  collector: Yup.string().required('Required'),

  tasks: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required('Required'),
      half: Yup.lazy((val) =>
        Array.isArray(val)
          ? Yup.array().of(Yup.string()).required('Required')
          : Yup.string().required('Required')
      ),
      part: Yup.array().when('type', {
        is: (type) => type === 'VALIDATION' || type === 'LOCATION',
        then: Yup.array()
          .min(1, 'Part should have at least one')
          .required('Required'),
        otherwise: Yup.array(),
      }),

      teamSide: Yup.string().when('type', {
        is: (type) => type === 'TAGGING' || type === 'FREEZE_FRAME',
        then: Yup.string().required('Required'),
        otherwise: Yup.string(),
      }),
    })
  ),
});

export const EDIT_PRIORITY = Yup.object().shape({
  priorityCategory: Yup.string().required('Required'),
  priority: Yup.string().required('Required'),
});

export const EDIT_QA = Yup.object().shape({
  collectionSquads: Yup.array().min(1),
});

export const REPORT_ISSUE = Yup.object().shape({
  // reason: Yup.string().required('Required'),
  type: Yup.string()
    .oneOf([...ISSUES_TYPES], 'Please choose valid type')
    .required('Required'),
  comment: Yup.string().when('reason', {
    is: 'OTHER',
    then: Yup.string()
      .transform((value) => value.trim())
      .required('Required')
      .max(160, 'The comment cannot exceed 160 characters.'),
    otherwise: Yup.string()
      .transform((value) => value.trim())
      .min(1, null)
      .max(160, 'The comment cannot exceed 160 characters.'),
  }),

  videoIssue: Yup.object().when('reason', {
    is: 'VIDEO_ISSUE',
    then: Yup.object().shape({
      isWholeMatch: Yup.boolean().required('Required'),
      half: Yup.string().when('isWholeMatch', {
        is: false,
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
      from: Yup.string().when('isWholeMatch', {
        is: false,
        then: Yup.string()
          .matches(TIME_REGEX, 'Please enter a valid time')
          .required('Required'),
        otherwise: Yup.string(),
      }),

      to: Yup.string().when('isWholeMatch', {
        is: false,
        then: Yup.string()
          .matches(TIME_REGEX, 'Please enter a valid time')
          .required('Required'),
        otherwise: Yup.string(),
      }),
    }),
    otherwise: Yup.object(),
  }),
  missingPlayer: Yup.object().when('type', {
    is: 'MISSING_PLAYER',
    then: Yup.object()
      .shape({
        teamSide: Yup.string().required('Required'),
        name: Yup.string(),
        shirtNumber: Yup.number()
          .integer()
          .min(1, 'Please enter a valid shirt number')
          .nullable(true)
          .transform((_, val) => (val === Number(val) ? val : null)),
      })
      .required('Required'),
    otherwise: Yup.object(),
  }),

  badShirt: Yup.object().when('type', {
    is: 'BAD_SHIRT',
    then: Yup.object()
      .shape({
        teamSide: Yup.string().required('Required'),
      })
      .required('Required'),
    otherwise: Yup.object({
      teamSide: Yup.string(),
    }),
  }),

  badVideo: Yup.object().when('type', {
    is: 'BAD_VIDEO',
    then: Yup.object().shape({
      isWholeMatch: Yup.boolean().required('Required'),

      half: Yup.string().when('isWholeMatch', {
        is: false,
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
      from: Yup.string().when('isWholeMatch', {
        is: false,
        then: Yup.string()
          .matches(TIME_REGEX, 'Please enter a valid time')
          .required('Required'),
        otherwise: Yup.string(),
      }),

      to: Yup.string().when('isWholeMatch', {
        is: false,
        then: Yup.string()
          .matches(TIME_REGEX, 'Please enter a valid time')
          .required('Required'),
        otherwise: Yup.string(),
      }),
    }),
    otherwise: Yup.object(),
  }),
});
export const REPORT_ISSUE_VIDEO = Yup.object().shape({
  type: Yup.string()
    .oneOf([...VIDEO_ISSUES], 'Please choose valid type')
    .required('Required'),
  comment: Yup.string().when('type', {
    is: 'OTHER',
    then: Yup.string()
      .transform((value) => value.trim())
      .required('Required')
      .max(160, 'The comment cannot exceed 160 characters.'),
    otherwise: Yup.string()
      .transform((value) => value.trim())
      .min(1, null)
      .max(160, 'The comment cannot exceed 160 characters.'),
  }),
  details: Yup.object()
    .when('type', {
      is: (type) => type === 'MISSING_MINUTES' || type === 'CONNECTION_ISSUE',
      then: Yup.object().shape({
        from: Yup.string().matches(TIME_REGEX, 'Please enter a valid time'), // Optional validation
        to: Yup.string().matches(TIME_REGEX, 'Please enter a valid time'), // Optional validation
      }),
      otherwise: Yup.object(),
    })
    .test(
      'fromToValidation',
      'Both "from" and "to" are required if one has a value',
      (value) => {
        const { from, to } = value;
        if (from || to) {
          // If from has a value and to is empty, or vice versa
          if ((from && !to) || (!from && to)) {
            return false; // Validation fails
          }
        }
        return true; // Validation passes otherwise
      }
    )
    .test(
      'fromBeforeToValidation',
      '"From" time must be before "to" time',
      (value) => {
        const { from, to } = value;
        if (from && to) {
          const fromTime = moment(from, 'mm:ss.SSS');
          const toTime = moment(to, 'mm:ss.SSS');

          return fromTime.isBefore(toTime); // Ensure "from" is before "to"
        }
        return true;
      }
    ),
});
export const BLOCK_MATCH = Yup.object().shape({
  issueId: Yup.string().required('Required'),
});

export const UNBLOCK_MATCH = Yup.object().shape({
  issueId: Yup.string().required('Required'),
});

export const CHANGE_PASSWORD = Yup.object().shape({
  password: Yup.string()
    .required('Required')
    .min(8, 'Minimum 8 characters')
    .matches(
      PASSWORD_REGEX,
      'Password should have at least one letter, one number and one special character'
    ),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match'
  ),
});

export const CREATE_UPDATE_PC = Yup.object().shape({
  number: Yup.number().required('Required'),
  task: Yup.string().required('Required'),
  locationId: Yup.string().required('Required'),
  serverId: Yup.string().when('task', {
    is: 'LIVE_STREAMING',
    then: Yup.string().required('Required'),
    otherwise: Yup.string().nullable(),
  }),
  satellites: Yup.array().min(0).max(4),
  VPN: Yup.array(),
  bein: Yup.boolean(),
  appleTv: Yup.boolean(),
});

export const CREATE_UPDATE_PROVIDER = Yup.object().shape({
  type: Yup.string()
    .oneOf(
      ['CHANNEL', 'STREAMING', 'IPTV', 'CUSTOMER_DRIVE', 'FEED_EXTENSION'],
      'Please choose valid type'
    )
    .required('Required'),
  IPTVDetails: Yup.object().when('type', {
    is: 'IPTV',
    then: Yup.object()
      .shape({
        name: Yup.string().required('Required'),
        videoOnDemand: Yup.boolean().required('Required'),
      })
      .required('Required'),
    otherwise: Yup.object(),
  }),
  channelDetails: Yup.object().when('type', {
    is: 'CHANNEL',
    then: Yup.object()
      .shape({
        name: Yup.string().required('Required'),
        satelliteId: Yup.string().required('Required'),
      })
      .required('Required'),
    otherwise: Yup.object(),
  }),
  streamingDetails: Yup.object().when('type', {
    is: 'STREAMING',
    then: Yup.object()
      .shape({
        name: Yup.string().required('Required'),
        noOfAccounts: Yup.number().required('Required'),
        noOfConcurrency: Yup.number().required('Required'),
        vpn: Yup.array(),
        videoOnDemand: Yup.boolean(),
        downloadable: Yup.boolean(),
      })
      .required('Required'),
    otherwise: Yup.object(),
  }),
});

export const CREATE_PC_ASSIGNMENT = Yup.object().shape({
  matchId: Yup.number().required(),
  pcId: Yup.string().required(),
});

export const ASSIGN_VIDEO_COORDINATOR = Yup.object().shape({
  collector: Yup.string().required('Required'),
});

export const UPDATE_SQUAD_NAME_VALIDATION = Yup.object().shape({
  squadId: Yup.string().required('Required'),
  squadName: Yup.string().required('Required'),
});
