import React from 'react';
import { useMutation } from '@apollo/client';
import { Dialog } from '@blueprintjs/core';
import { useFormik } from 'formik';
import { Button, Text, TextInput } from 'components';
import { prop, isNil } from 'ramda';
import { useModal } from 'providers/modalProvider';
import { useToast } from 'providers/toastProvider';
import { useIntl, FormattedMessage } from 'react-intl';
import { UPDATE_SQUAD_NAME_VALIDATION } from 'validations';
import { UPDATE_SQUAD_NAME_MUTATION } from 'gql';

export default function EditSquadName() {
  const { modalState, modalDispatch } = useModal();
  const { showToast } = useToast();
  const intl = useIntl();
  const { squadId, squadName } = prop('payload', modalState) || {};
  const [updateSquadName, { loading }] = useMutation(
    UPDATE_SQUAD_NAME_MUTATION,
    {
      onError: () => {
        modalDispatch({ type: 'ClOSE_ALL' });
        showToast({
          message: intl.formatMessage({
            id: 'containers.squads.createSquad.error',
          }),
          icon: 'error',
        });
      },
      onCompleted: () => {
        modalDispatch({ type: 'ClOSE_ALL' });
        showToast({
          message: intl.formatMessage({
            id: 'containers.squads.updateName.success',
          }),
        });
      },
    }
  );

  const formik = useFormik({
    initialValues: { squadId, squadName },
    enableReinitialize: true,
    validationSchema: UPDATE_SQUAD_NAME_VALIDATION,
    onSubmit: ({ squadId, squadName }) =>
      updateSquadName({
        variables: {
          input: {
            squadId,
            squadName,
          },
        },
      }),
  });

  const disableSubmit = isNil(formik.values.squadName);
  const title = (
    <Text variant="h2" data-testid="main-dialogTitle-addNewSquad">
      <FormattedMessage id="containers.squads.editSquadName" />
    </Text>
  );

  return (
    <Dialog
      isOpen={modalState?.open}
      title={title}
      onClose={() => modalDispatch({ type: 'ClOSE_ALL' })}
      canOutsideClickClose={false}
    >
      <div className="add-squad-form-container">
        <TextInput
          required
          type="text"
          labelText={intl.formatMessage({ id: 'containers.squads.squadName' })}
          placeholder={intl.formatMessage({
            id: 'containers.squads.squadName',
          })}
          value={formik.values.squadName}
          onChange={formik.handleChange('squadName')}
          error={formik.touched.squadName && formik.errors.squadName}
          name="squadName"
        />
      </div>

      <div className="footer-action-btns" data-testid="addNewSquad-footer">
        <Button
          outlined
          intent="Primary"
          text={intl.formatMessage({ id: 'containers.all.btn.cancel' })}
          onClick={() => modalDispatch({ type: 'ClOSE_ALL' })}
          data-testid="cancel-button-footer"
        />
        <Button
          intent="Primary"
          text={intl.formatMessage({ id: 'containers.all.btn.save' })}
          onClick={formik.submitForm}
          loading={loading}
          disabled={disableSubmit}
          data-testid="submit-button-footer"
        />
      </div>
    </Dialog>
  );
}
