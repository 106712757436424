import { gql } from '@apollo/client';

export default gql`
  mutation updateSquadName($input: UpdateSquadNameInput!) {
    updateSquadName(input: $input) {
      id
      name
    }
  }
`;
